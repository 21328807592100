<template>
  <div>
    <v-menu
      bottom
      left
      min-width="200"
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          class="ml-2"
          min-width="0"
          text
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>{{ mdiAccount }}</v-icon>
        </v-btn>
      </template>

      <v-list
        :tile="false"
        flat
        nav
      >
        <template v-for="(p, i) in profile">
          <v-divider
            v-if="p.divider"
            :key="`divider-${i}`"
            class="mb-2 mt-2"
          />

          <app-bar-item
            v-else
            :key="`item-${i}`"
          >
            <v-list-item-title
              style="height: 48px;"
              class="d-flex align-center px-2"
              @click="onMenuItemClicked(p.title)"
              v-text="p.title"
            />
          </app-bar-item>
        </template>
      </v-list>
    </v-menu>
    <v-dialog
      v-model="showAboutDialog"
      max-width="400"
    >
      <v-card class="pa-6">
        <v-sheet>
          <div class="text-body-1">
            Lumii
          </div>
          <div class="text-body-1 pt-6">
            Version : <span class="font-weight-bold"> {{ version }} </span>
          </div>
          <div class="text-body-1 pt-6">
            &copy; {{ (new Date()).getFullYear() }}, Arizona Facility Services
          </div>
        </v-sheet>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import common from '@/api/common.js'
  import { mdiAccount } from '@mdi/js'

  export default {
    name: 'DefaultAccount',

    data: () => ({
      profile: [
        { title: 'About' },
        { title: 'Log out' },
      ],
      showAboutDialog: false,
      version: process.env.PACKAGE_VERSION,
      mdiAccount: mdiAccount,
    }),

    methods: {
      onMenuItemClicked: function (menuItem) {
        console.log('hi')
        if (menuItem === 'Log out') {
          common.logoutUser(this.$router)
        } else if (menuItem === 'About') {
          this.showAboutDialog = true
        }
      },
    },
  }
</script>
